Vue.http.headers.common['Authorization'] = 'Bearer '+document.querySelector('meta[name=api-token]').getAttribute('content');

new Vue({
    el: '#notifications',
    data: {
        notifications: [],
        filters: {
            unseen: function(notification) {
                return ! notification.seen;
            }
        },
    },
    computed: {
        new: function() {
            return this.notifications.filter(this.filters.unseen);
        }
    },
    ready: function() {
        this.getNotifications();

        // We'll check for new notifications every 30 seconds
        window.setInterval(function() {
            this.authCheck();
        }.bind(this), 600000);
    },
    methods: {
        getNotifications: function() {
            this.$http.get('/api/notifications').then(function(response) {
                    this.notifications = response.data.notifications;
                }.bind(this), function (response) {
            }.bind(this));
        },
        authCheck: function() {
            this.$http.get('/auth-check').then(function (response) {
                if (response.data.logged_in === false) {
                    window.location = '/login?timeout=1';
                }
                }.bind(this), function (response) {
            }.bind(this));
        },
        seeNotifications: function() {
            this.$http.put('/api/notifications').then(function(response) {
                this.notifications.forEach(function(notification) {
                    notification.seen = true;
                });
                }.bind(this), function (response) {
            }.bind(this));
        },
    }
});
